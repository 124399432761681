export const environment = {
  envName: "stage_eu",
  enrollmentRegion: "EU",
  "genesis": {
    "url": "https://app.member.stage.virginpulse.com",
    "iamUrl": "https://iam.stage.virginpulse.com/auth/realms/virginpulse",
    "home": "/#/home"
  },
  "personifyHealth": {
    "url": "https://app.stage.personifyhealth.com",
    "iamUrl": "https://login.stage.personifyhealth.com/auth/realms/platform",
  },
  "wolverine": {
    "url": "https://member.stage.virginpulse.com"
  },
  "signIn": {
    "vpLink": "https://iam.stage.virginpulse.com",
    "phLink": "https://login.stage.personifyhealth.com"
  },
  "zendesk": {
    "url": "personifyhealth1644441984.zendesk.com/"
  },
  "termsConditions": {
    "url": "https://member.stage.virginpulse.com/devices/sponsor/terms?groupId=1&"
  },
  "defaultLanguage": "en-GB",
  "newrelic": {
      licenseKey: "d163f43855",
      appId: "39696348"
  },
  "passport": {
    "home": "https://app.stage.personifyhealth.com/#/passport"
  },
  "mixpanel": {
      "token": "d87b520401dd755690bd3459fdcc01bd"
  },
  "customEligibilityDataSources": {
        "aetna": {
            "name": "AetnaMockVSI",
            "redirectUrl": "https://virgin-pulse-dev.onelogin.com/client/apps/select/717309349"
        }
    },
    cognigy: {
      url: "https://webchat-ui-bundle.qa-cdn.personifyhealth.com/main.js"
    },
    "tpaProviderHub": "https://mycarehc.com/provider",
};
